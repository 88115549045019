/* 
.details-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
}

.inner-detail-div{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin:2%;
    width:50%;
    padding: 5%;
    background-color: #242324;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

}
.inner-div{
    width:30%;
    display: flex;
    justify-content: flex-start;
}

.div-1{
    display: flex;

}

.p-tag{
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    text-align: left;
    width:100%;
}



.chakra-text{
    word-wrap: break-word;
}

.sdf{
    width: 70%;
} */


.outer-main-div{
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    padding-top: 2%;
}
.inner-main-div{
    display: flex;
    flex-direction: row;
    height: 80%;
    width: 80%;
}
.inner-child-div{
    padding:5%;
    display: flex;
    flex-direction: column;
    width:50%;
    margin:1%;
    background-color: #242324;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    
}
.inner-child-div-scroll{
    padding:5%;
    display: flex;
    flex-direction: column;
    width:50%;
    margin:1%;
    background-color: #242324;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    overflow-y: scroll;
}
.first-inner-child-left-special{
    display: flex;
    width: 100%; 
}
.first-inner-child-left{
    display: flex;
    flex-direction: column;
    width: 100%; 
}

.p-tag{
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    text-align: left;
    width:100%;
}
.p-tag-small{
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    text-align: left;
    width:100%;
}
.form-div{
    display: flex;
    align-items: center;
    justify-content: space-around;

}
.input-box{
    padding:2%;
    width: auto !important;
}

.create-button-div{
    display: flex;
    justify-content: flex-end;
}

.wallet-number-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.wallet-number-div-2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.long-p-tag{
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: left;
    text-align: left;
    
    word-wrap: break-word;
}


.style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.style-1::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
    border-radius: 10px;
}

.style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #B6D391;
}
