.Intro {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.h1-tag {
  font-weight: bolder;
  color: #333;
  text-align: center;
  font-size: 2vw;
}

.intro {
  font-size: 1.25em;
  height: 100vh;
  display:flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;

}

.inner-div{
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image img {
  width: 25em;
  height: auto;
  border-radius: 30%;
  padding-left: 2em;
}