@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap");

* {
    font-family: nunito, Helvetica, Neue, Arial, sans-serif;
    font-variant: normal;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 600;
}

.styles-container {
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
    /* background: url(images/pic.jpg); */
    /* background-size: cover; */
}

.styles-wrapper {
    position: absolute;
    width: 455px;
    height: 485px;
    border-radius: 30px;
    transform: rotate(5deg);
    background: rgba(255, 255, 255, 0.53);
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.115),
        -2px -0px 15px 2px rgba(0, 0, 0, 0.054);
}

.styles-wrapper2 {
    position: absolute;
    width: 455px;
    height: 485px;
    border-radius: 30px;
    transform: rotate(10deg);
    background: rgba(255, 255, 255, 0.53);
}

.styles-box {
    width: 450px;
    height: 480px;
    background: #fff;
    /* backdrop-filter: blur(20px); */
    border-radius: 30px;
    padding: 40px;
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.1),
        -2px -0px 15px 2px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.styles-header {
    margin-bottom: 40px;
}

header {
    display: flex;
    align-items: center;
}

.styles-header header {
    display: flex;
    justify-content: right;
}

header img {
    width: 25px;
}

.styles-header p {
    font-size: 25px;
    font-weight: 600;
    margin-top: 10px;
}

.styles-headerNext p {
    font-size: 14px;
    top: 90px;
    font-weight: 1;
    position: absolute;
}

.input-box {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    position: relative;
}

i {
    font-size: 22px;
    position: absolute;
    top: 35px;
    right: 12px;
    color: #595b5e;
}

input {
    height: 40px;
    border: 2px solid rgb(153, 157, 158);
    border-radius: 7px;
    margin: 5px 0;
    outline: none;
}

.input-field {
    font-weight: 500;
    padding: 0 10px;
    font-size: 17px;
    color: #333;
    background: transparent;
    transition: all 0.3s ease-in-out;
}

.input-field:focus {
    border: 2px solid rgb(89, 53, 180);
}

.input-field:focus~i {
    color: rgb(89, 53, 180);
}

.input-submit {
    background: #22663e;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.input-submit:hover {
    background: #128f46;
}

.forgot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.forgot span {
    color: #000;
    cursor: pointer;
    display: flex;
}

.forgot span:hover {
    text-decoration: underline;
}