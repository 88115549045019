.group {
    display: block;
    position: relative;
    background: none;
}

.intro {
    font-size: 1.25em;
    height: 100vh;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;
  
  }

  .field-main-div{
    display: flex;
}

.field-div{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1%;
}

.field-lable{
    color: white;
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
}