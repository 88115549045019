.form-box{
    background-color: #242324 !important;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.error-message{
    color: #bb2a2a !important;
}

.label{
    color: #FCFCFD !important;
}

.main-div {
    font-size: 1.25em;
    height: 100vh;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;
  
  }

  .reg-button{
    background-color: #B6D391 !important;
}