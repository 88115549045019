.sync-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Style the form */
.sync-form {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Style the input fields */
.sync-form input[type=text],
.sync-form input[type=password] {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Style the submit button */
.sync-form input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

/* Center the submit button */
.btn-container {
  display: flex;
  justify-content: center;
}