.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  padding: 2rem;
}

.title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.yes-button {
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: none;
  background-color: #34A853;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-bottom: 1rem;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

.yes-button:hover {
  background-color: #2F8E41;
}

.yes-button:active {
  transform: scale(0.95);
}

.no-button {
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: none;
  background-color: #EA4335;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  margin-left: 1rem;
  margin-top: 1rem;
}

.no-button:hover {
  background-color: #C23E2D;
}

.no-button:active {
  transform: scale(0.95);
}