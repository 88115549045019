.form-box{
    background-color: #242324 !important;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.field-main-div{
    display: flex;
}

.field-div{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1%;
}

.field-lable{
    color: white;
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
}

.slect{
    padding: 2.5%;
    border-radius: 5px;
}

.reg-button{
    background-color: #B6D391 !important;
}