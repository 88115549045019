/* Navbar styles for screens wider than 768px */
.navbar-outer-body {
  padding: 1%;
  position: fixed;
  width: 100%;
}

.navbar-main-body {
  overflow: hidden;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #242324;  
  padding: 1%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.links-pages {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 1rem;
  transition: all 0.3s ease-in-out;
}

.links-pages:hover {
  color: #f5f5f5;
  transform: scale(1.1);
}

.logo-image {
  max-height: 35px;
  border-radius: 5px;
}

.button-div {
  display: flex;
}

.button-30 {
  margin-left: 3%;
  margin-right: 3%;
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  height: 35px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
}

.button-30:hover {
  transform: translateY(-2px);
  background-color: #b6d391;
}

.button-30:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

.account-image {
  max-height: 35px;
  border-radius: 5px;
}
.navbar-toggle {
  font-size: 30px;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 10px;
}

@media (max-width: 768px) {

.links-pages {
  font-size: .9rem;
}
.navbar-item img {
  max-height: .3rem;
}
.button-30 {
  font-size: .9rem;
}
}